export const NECESSARY_THIRD_SDK = {
  // 'PayPal-GApaypal': [
  //   //Load the client component
  //   'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
  //   //Load the PayPal Checkout component
  //   'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
  //   //手机设备数据
  //   'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
  // ],
  'PayPal-GApaypal': [
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
        id: 'ppga-sdk-client'
      }
    },
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
        id: 'ppga-sdk-checkout'
      }
    },
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
        id: 'ppga-sdk-data'
      }
    }
  ],
  // routepay-card
  'routepay-card': [
    {
      label: 'script',
      attrs: {
        src: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.js'
      }
    },
    {
      label: 'link',
      attrs: {
        href: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.css',
        rel: 'stylesheet'
      }
    }
  ]
}
