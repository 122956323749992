export function debuggerLog(...args) {
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info(
      '%cOneClickPay:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n'
    )
  } catch (e) {
    console.log(...args)
  }
}
